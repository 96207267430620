import React, { useState, useEffect } from "react";
import moment from "moment";
import timer from "../img/timer.png";

function Timer(props) {
  const [difference, setDifference] = useState("");
  const [s_diff, setS_diff] = useState("");

  useEffect(() => {
    calculateDifference();
    updateDifference();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setS_diff((s_diff) => s_diff - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (s_diff <= 0) {
      props.onCooldownTimer("true");
    } else {
      props.onCooldownTimer("false");
    }
  }, [s_diff]);

  const calculateDifference = () => {
    // Subtract current time from submission time, render difference into MM:SS format
    const now = moment();
    const timestamp = moment(props.timestamp);
    //get difference in seconds
    setS_diff(timestamp.diff(now, "seconds"));
  };

  const updateDifference = () => {
    //update the timer in mm:ss format
    setDifference(
      moment
        .utc(moment.duration(s_diff, "seconds").asMilliseconds())
        .format("mm:ss")
    );
  };

  return (
    <>
      {props.displaytimer ? null : (
        <div className="timer_pos">
          <p>
            <img className="timer_icon" src={timer} />
          </p>
          <div className="timer">{difference}</div>
        </div>
      )}
    </>
  );
}
export default Timer;
