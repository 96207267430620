import React, { Component } from "react";
import Textbox from "./textbox";
import Textbox_Sleep from "./textbox_sleep";
import Textbox_Loading from "./textbox_loading";
import axios from "axios";

class Switch extends Component {
  state = {
    val: "loading",
  };

  componentDidMount() {
    axios
      .get("https://twitanon-api.herokuapp.com/chat_switch/1/", {
        auth: {
          username: "twitanonadmin",
          password: "Bluedragon12",
        },
      })
      .then((res) => {
        if (res.data.switch === "1") {
          this.setState({ val: "off" });
        } else {
          this.setState({ val: "on" });
        }
      });
  }

  render() {
    switch (this.state.val) {
      case "loading":
        return <Textbox_Loading />;
      case "off":
        return <Textbox_Sleep />;
      case "on":
        return <Textbox />;
    }
  }
}

export default Switch;
