import React, { Component } from "react";
import Tweet from "./tweet.jsx";
import axios from "axios";
import { Spinner } from "react-bootstrap";

class TweetList extends Component {
  state = {
    tweet_ids: [],
    loading: true,
  };

  componentDidMount() {
    axios
      .get(`https://twitanon-api.herokuapp.com/recent_tweets/?format=json`)
      .then((res) => {
        this.setState({ tweet_ids: res.data, loading: false });
      });
  }

  render() {
    return (
      <div className="recent-area" id="recent-Tweets">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h4>Recent tweets</h4>
              </div>
            </div>
          </div>
          <div className="row">
            {/* plug in tweet component here*/}
            {this.state.loading ? (
              <>
                <div className="col-lg-4 col-md-6">
                  <Spinner animation="border" variant="primary" />
                </div>
                <div className="col-lg-4 col-md-6">
                  <Spinner animation="border" variant="primary" />
                </div>
                <div className="col-lg-4 col-md-6">
                  <Spinner animation="border" variant="primary" />
                </div>
              </>
            ) : (
              this.state.tweet_ids.map((tweet) => (
                <Tweet
                  tweetId={tweet.id_str}
                  loading={this.state.loading}
                  key={tweet.id_str}
                />
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TweetList;
