import React, { Component } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import moment from "moment";
import autosize from "autosize";
import { ClipLoader } from "react-spinners";

class Textbox extends Component {
  state = {
    textAreaValue: "",
    counterval: "0",
    timestamp: "",
    active: "emptyval",
    loading: true,
  };

  client = new W3CWebSocket("wss://twitanon-api.herokuapp.com/timer/");

  componentDidMount() {
    //handle websocket
    this.client.onopen = () => {
      console.log("Websocket connected, stop snooping");
    };
    this.client.onmessage = (val) => {
      const dataFromServer = JSON.parse(val.data);
      this.setState({ timestamp: dataFromServer.timer, loading: false });
    };
    //handle textbox resizing
    this.textarea.focus();
    autosize(this.textarea);
  }

  handleChange = (event) => {
    // handles characters changing in textfield + character counter
    this.setState({
      [event.target.name]: event.target.value,
      counterval: event.target.value.length,
    });
  };

  handleSubmit = (event) => {
    // handles POST request sending submission text, prevents page from refreshing after submit, resets states
    const submission = { text_content: this.state.textAreaValue };
    axios
      .post("https://twitanon-api.herokuapp.com/submissions/", submission, {
        auth: {
          username: "twitanonadmin",
          password: "Bluedragon12",
        },
      })
      .then((res) => {
        //console.log(res);
        this.setState({
          textAreaValue: "",
          counterval: "0",
        });
      });
  };

  handleTimer = () => {
    // handles making PUT request with updated cooldown timer upon submission,
    const timestamp = moment().add(30, "minutes");
    const curr_time = { time: timestamp };

    axios
      .put("https://twitanon-api.herokuapp.com/cooldown_timer/1/", curr_time, {
        auth: {
          username: "twitanonadmin",
          password: "Bluedragon12",
        },
      })
      .then((res) => {
        console.log(res);
      });
  };

  handleDisplayTB = (val) => {
    if (val === "true") {
      this.setState({
        active: "true",
      });
    } else if (val === "false") {
      this.setState({
        active: null,
      });
    }
  };
  //find a way to conditionally render the switch
  render() {
    return (
      <div className="texarea">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="textarea-block">
                <form action="#">
                  <div className="timer_loader">
                    <ClipLoader
                      color={"#0D6EFD"}
                      size={80}
                      speedMultiplier={1}
                    />
                  </div>
                  <textarea
                    name="textAreaValue"
                    id=""
                    className="loading_box"
                    ref={(c) => (this.textarea = c)}
                    cols="30"
                    rows={10}
                    placeholder="What's happening?"
                    maxLength="280"
                    value={this.state.textAreaValue}
                    onChange={this.handleChange}
                    disabled={true}
                    readOnly
                  />
                  <div className="textarea-link">
                    <a className="counter">{this.state.counterval}/280</a>
                  </div>
                  <button
                    type="submit"
                    onClick={(e) => {
                      this.handleTimer();
                      this.handleSubmit(e);
                    }}
                    disabled={true}
                  >
                    Tweet
                  </button>
                </form>
              </div>
              <div className="text-inner">
                <p>
                  Don't break{" "}
                  <a
                    href="https://twitter.com/en/tos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter Terms of Service
                  </a>
                  , don't post hateful content, and no advertisements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Textbox;
