import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

function FAQ() {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <div className="faq-wrapper">
      <Accordion defaultActiveKey={activeId} id="accordion">
        <div className="card">
          <div className="card-header">
            <Accordion.Toggle
              onClick={() => toggleActive("0")}
              as={Card.Header}
              eventKey="0"
              className="card-header"
              id="headingOne"
              variant="link"
            >
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  aria-expanded={activeId === "0" ? "true" : "false"}
                  style={{ textDecoration: "none" }}
                >
                  What's with the cooldown timer?
                  <i className="fal fa-angle-right"></i>
                </button>
              </h5>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="0" id="faq1">
            <div className="card-body">
              To respect Twitter's API limits and as a general courtesy, the
              cooldown timer is set to 30 minutes globally. Keep in mind, this
              cooldown applies to whether or not the previous submission gets
              posted. The downtime also benefits me to make sure submissions are
              cleared before they get pushed out.
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle
              as={Card.Header}
              className="card-header"
              id="headingTwo"
              onClick={() => toggleActive("1")}
              eventKey="1"
              variant="link"
            >
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  aria-expanded={activeId === "1" ? "true" : "false"}
                  style={{ textDecoration: "none" }}
                >
                  Which Tweets get posted and which don't?
                  <i className="fal fa-angle-right"></i>
                </button>
              </h5>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="1" id="faq2">
            <div className="card-body">
              In a nutshell, Tweets that break TOS, release an individuals
              private information, promote anything, or are too controversial
              aren’t allowed. I understand that the latter is too subjective,
              but I clarify further in the <b>Guidelines</b> page. At the end of
              the day, I want to make sure this bot is available for everyone,
              and I would much rather skip on a submission calling for violence
              or for Hitler to come back than risk getting shutdown. The
              promotion rule should be self explanatory.
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle
              onClick={() => toggleActive("2")}
              as={Card.Header}
              eventKey="3"
              className="card-header"
              id="headingOne"
              variant="link"
            >
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  aria-expanded={activeId === "2" ? "true" : "false"}
                  style={{ textDecoration: "none" }}
                >
                  How long does it take for a Tweet to be approved?
                  <i className="fal fa-angle-right"></i>
                </button>
              </h5>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="3" id="faq1">
            <div className="card-body">
              It varies. Most Tweet verifications are fast so submissions are
              posted in ~1 minute, but depending on the content or if the
              algorithm hits a red flag then it'll take a little longer. That
              being said, PLEASE stop posting smut because I’ll have to see it
              with my own eyes. And frankly, everyone else will have to if it
              gets approved.
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle
              onClick={() => toggleActive("3")}
              as={Card.Header}
              eventKey="4"
              className="card-header"
              id="headingOne"
              variant="link"
            >
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  aria-expanded={activeId === "3" ? "true" : "false"}
                  style={{ textDecoration: "none" }}
                >
                  What's with the "Be Right Back" message?
                  <i className="fal fa-angle-right"></i>
                </button>
              </h5>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="4" id="faq4">
            <div className="card-body">
              Since Tweets are very reliant on context, I want to avoid
              automoderation and blanket bans on words to give users with good
              intentions more opportunities to express themselves. However, the
              only flaw with a human-only verification system is the human.
              Until I can reliably find others to entrust with approving
              submissions, they won't be accepted during times I can't respond
              to them, like during 12am - 7am EST.{" "}
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle
              onClick={() => toggleActive("4")}
              as={Card.Header}
              eventKey="5"
              className="card-header"
              id="headingOne"
              variant="link"
            >
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  aria-expanded={activeId === "4" ? "true" : "false"}
                  style={{ textDecoration: "none" }}
                >
                  Is it true you're attractive and insanely charismatic?
                  <i className="fal fa-angle-right"></i>
                </button>
              </h5>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="5" id="faq5">
            <div className="card-body">No lmao</div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
}

export default FAQ;
