import "./css/style.css";
import "./css/all.min.css";
import "./css/animate.css";
import "./css/bootstrap.min.css";
import "./css/default.css";
import "./css/fontawesome.css";
import "./css/meanmenu.css";
import "./css/nice-select.css";
import "./css/responsive.css";
import Navbar from "./components/navbar.jsx";
import Switch from "./components/switch.jsx";
import TweetList from "./components/tweetlist.jsx";
import FAQ from "./components/faq.jsx";
import Rules from "./components/rules.jsx";

import hero_bg from "./img/hero-bg.png";
import t_bg2 from "./img/transparent-2.png";
import faq_bg from "./img/twieet.png";

function App() {
  return (
    <>
      <Navbar />
      {/* home section starts here */}
      <div
        className="hero-area min-height"
        style={{ backgroundImage: `url(${hero_bg})` }}
        id="home"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-wrapper text-center">
                <h2>
                  Send Tweets anonymously <br />
                  for the world to see.
                </h2>
                <img className="trans-2" src={t_bg2} alt="t_bg2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch />
      {/* recent tweets section starts here */}
      <TweetList />
      {/* about area section starts here */}
      <div className="about-area" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-title">
                <h2>About TwitAnon</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="about-thumb">
                <img src={faq_bg} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-site-right">
                <div className="about-all-content">
                  <h5>A little More About The Project</h5>
                  <h4>
                    Frequently Asked Questions <br />
                  </h4>
                  <p>
                    A lot of Twitter bots these days incorporate some sort of
                    user interactivity, like replying with a command which the
                    bot responds to. Some of these bots are even more creative,
                    letting users control a video game character via Retweets
                    and Likes. Inspired by these programmatically impressive
                    feats, I decided to create something that breaks grounds
                    that no other bot touched on; complete Tweet access to you,
                    the users. Of course, with a few algorithmic and human
                    roadblocks to make sure every tweet submitted follows TOS,
                    you can submit text and have it sent via my bot for everyone
                    to see. Currently, images and videos are in the works to be
                    supported. For a more indepth review on the rules and what’s
                    allowed, check out the guidelines.
                  </p>
                </div>
                <FAQ />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* rules section starts here */}
      <div className="rulse-area" id="rules">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-title">
                <h2>Guidelines</h2>
              </div>
            </div>
          </div>
          {/* enter rules func here */}
          <Rules />
        </div>
      </div>
      {/*footer start */}
      <div className="foter-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="footer-text">
                <p>© 2021 TwitAnon</p>{" "}
                <small style={{ color: "rgb(204, 204, 204)" }}>
                  Design by Hasibul Prottoy, Logo by @skulIiosis
                </small>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 order-first order-md-last">
              <div className="footer-social">
                <a
                  href="https://github.com/dean-mccoppin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github-square"></i>
                </a>
                <a
                  href="https://twitter.com/ireallylikemiIk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-angellist"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
