import React from "react";
import { Tab, Nav } from "react-bootstrap";

function Rules() {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <div className="row">
        <div className="col-lg-3 col-md-4">
          <Nav variant="pills" className="vertical-pills">
            <Nav.Item>
              <Nav.Link
                eventKey="first"
                role="tab"
                aria-selected="true"
                className="nav-link"
              >
                General
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="second"
                role="tab"
                aria-selected="false"
                className="nav-link"
              >
                Advertising/Promotion
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="third"
                role="tab"
                aria-selected="false"
                className="nav-link"
              >
                Controversial Tweets
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="fourth"
                role="tab"
                aria-selected="false"
                className="nav-link"
              >
                Private Information
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="col-lg-9 col-md-8">
          <div className="vertical-wrap-text">
            <Tab.Content className="tab-content">
              <Tab.Pane className="tab-pane" eventKey="first">
                <div
                  className="tab-pane"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <h4>General Information</h4>
                  <p>
                    The purpose of these rules are to not only ensure TwitAnon
                    is used for its original purposes, but to also respect
                    Twitter’s guidelines as well. Opening a Twitter account that
                    let’s anyone, from anywhere, submit their own content can be
                    a huge liability without the proper safeguards to keep the
                    bots timeline healthy. When these rules were drafted, I
                    imagined someone with the worst intentions trying to do
                    whatever it takes to use TwitAnon for malicious reasons.
                    But, I also didn’t want to create restrictive rules for 95%
                    of users because of the 5% who might abuse the system. With
                    all of this in mind, I settled on very few hard rules with
                    no wiggle room (advertising, doxxing), but the rest are
                    lenient and are very much a case-by-case basis.
                  </p>
                  <p>
                    I know that those “case-by-case” situations run through me
                    exclusively, which may bring up questions about bias or
                    letting Tweets related to X go through but not related to Y.
                    It’s part of my responsibility, not only as a creator of a
                    public service but as a responsible person to omit any
                    favoritism and remain neutral during manual approvals. I
                    wouldn’t have gone through the effort of building this
                    website from scratch just so I could push a personal agenda
                    via a Twitter bot.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="second">
                <div
                  className="tab-pane"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <h4>Can I post my…?</h4>
                  <p>
                    Gofundme's, commission prices, OnlyFans, and promoting any
                    of your services via TwitAnon is strictly prohibited. Users
                    wouldn’t appreciate their feed being populated by this type
                    of content, you included, so don’t post them on here.
                    Unfortunately, I would’ve allowed links to charity events
                    and organizations, but that would set a new precedent on
                    what promotions are allowed and what aren’t. So for
                    simplicity’s sake, &nbsp;
                    <b>
                      no promotions, advertisements and etc. are going to be
                      approved.
                    </b>
                  </p>
                  <p>
                    However, it’s not against the rules to post something you or
                    someone else made. That’s what this platforms for! You can
                    post art with a link to the artists profile, but linking to
                    a Patreon/art store will get your Tweet denied. Similarly,
                    you can post a clip of a streamer, but not a link to their
                    channel.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="third">
                <div
                  className="tab-pane"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <h4>Can I say…?</h4>
                  <p>
                    This one’s a doozy. Although I built this bot for the sake
                    of speaking your mind, there has to be limitations beyond
                    Twitter's Terms of service if I plan on making this service
                    available to literally everyone. I consider Tweets to be
                    “too controversial” if they have the potential to draw
                    extreme amounts of <i>warranted</i> negative attention. I
                    put special emphasis on “warranted”, because I know the
                    Twitter atmosphere can be volatile at the drop of a hat.
                    &nbsp;
                    <b>
                      That being said, any bait with the sole intent of inciting
                      unhealthy controversy also won’t be approved.
                    </b>{" "}
                    Low effort attempts of hate/political trolling to create a
                    chaotic comment section isn’t what I built this platform
                    for; and although I hope people don’t use TwitAnon just for
                    politics I also want to give people the freedom to discuss
                    current events with their privacy in mind.
                  </p>
                  <p>
                    Here’s a couple of example cases to give you an idea of
                    where the line is drawn. A Tweet with “6 million wasn’t
                    enough”, “Kill all men”, or “Fuck <i>(insert race)</i>” is
                    considered low effort bait, and a constant stream of Tweets
                    in this nature would quickly devolve into a toxic timeline
                    with TwitAnon being shut down. However, I stand by this
                    project's original goal. &nbsp;
                    <b>
                      If an approved Tweet attracts a hostile crowd, it will
                      stay up.
                    </b>{" "}
                    Tweets criticizing an artist, public figure, or a fanbase is
                    completely fine, as long as you’re not targeting them
                    because of race, religion, etc.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="fourth">
                <div
                  className="tab-pane"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <h4>Can I post someone’s…?</h4>
                  <p>
                    &nbsp;
                    <b>
                      Absolutely no personal information that isn’t public can
                      be released via this bot
                    </b>
                    . Whatever you post will be available for anyone to view,
                    and the process of deleting a Tweet will take some time.
                    Please don’t post your phone number, government assigned
                    information, or your address, or your Tweet won’t be posted.
                    There’s a zero tolerance rule for doxxing, both on Twitter
                    and on here.
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </div>
    </Tab.Container>
  );
}

export default Rules;
