import React, { Component } from "react";
import timer_sleep from "../img/timer_sleep.png";

class Textbox extends Component {
  state = {};

  //find a way to conditionally render the switch
  render() {
    return (
      <div className="texarea">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="textarea-block">
                <form action="#">
                  <div className="timer_pos_sleep">
                    <p>
                      <img
                        className="timer_icon_sleep"
                        src={timer_sleep}
                        alt={""}
                      />
                    </p>
                    <div className="timer_sleep">Be Right Back</div>
                  </div>
                  <textarea
                    name="textAreaValue"
                    className="disabled_box"
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="What's happening?"
                    maxLength="280"
                    disabled
                    readOnly
                  />
                  <div className="textarea-link">
                    <a className="counter">0/280</a>
                  </div>
                  <button
                    disabled="true"
                    className="disabled_twtbtn"
                    type="submit"
                  >
                    Tweet
                  </button>
                </form>
              </div>
              <div className="text-inner-sleep">
                <p className="message-info">
                  <a href="#about">Why am I seeing this?</a>
                </p>
                <p>
                  Don't break{" "}
                  <a
                    href="https://twitter.com/en/tos"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter Terms of Service
                  </a>
                  , don't post hateful content, and no advertisements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Textbox;
