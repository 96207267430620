import React, { Component } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";

class Tweet extends Component {
  render() {
    return (
      <div className="col-lg-4 col-md-6">
        <TwitterTweetEmbed tweetId={this.props.tweetId} />
      </div>
    );
  }
}

export default Tweet;
