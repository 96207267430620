import React, { Component } from "react";
import ScrollspyNav from "react-scrollspy-nav";
import logo from "../img/logo.svg";
import t_bg from "../img/transparent-1.png";

class Navbar extends Component {
  state = {
    isOpened: false,
  };

  handleScroll = () => {
    if (window.scrollY >= 140) {
      document.querySelector(".header-area").className = "header-area Sticky";
    } else {
      document.querySelector(".header-area").className = "header-area ";
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleOpenMenu = () => {
    this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
  };

  render() {
    return (
      <div className="header-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-12">
              <div className="header-logo">
                <a href="index.html">
                  <img src={logo} alt="logo" />
                </a>
                <div className="toggle-bar" onClick={this.handleOpenMenu}>
                  <i className="fal fa-align-right"></i>
                </div>
                <img className="trans-1" src={t_bg} alt="t_bg" />
              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="header-site-menu">
                <div
                  className={`header-mainmenu ${
                    this.state.isOpened ? "active" : ""
                  }`}
                >
                  <div
                    className="close-icon"
                    onClick={() => this.setState({ isOpened: false })}
                  >
                    <i className="fal fa-times"></i>
                  </div>
                  <ScrollspyNav
                    scrollTargetIds={[
                      "home",
                      "recent-Tweets",
                      "about",
                      "rules",
                    ]}
                    offset={-100}
                    activeNavClass="is-active"
                    scrollDuration="800"
                  >
                    <ul>
                      <li>
                        <a href="#home">Home</a>
                      </li>
                      <li>
                        <a href="#recent-Tweets">Recent Tweets</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#rules">Guidelines</a>
                      </li>
                    </ul>
                  </ScrollspyNav>
                </div>
                <div className="right-btn">
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=twitteranonapp@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
